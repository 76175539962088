import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PieceJointeUploaderComponent} from './piece-jointe-uploader.component';
import {PieceJointeUploadComponent} from './piece-jointe-upload/piece-jointe-upload.component';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MatAngularModule } from '../../mat-angular.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatAngularModule,
    FlexLayoutModule,
  ],
  declarations: [
    PieceJointeUploaderComponent,
    PieceJointeUploadComponent
  ],
  exports: [
    PieceJointeUploaderComponent
  ]
})
export class PieceJointeUploaderModule { }
