/**
 * Indices des onglets sur un ticket : commentaires / annotations
 */
export enum TabListIndex {
  commentaire = 0,
  annotations = 1
}

/**
 * Indices des onglets des éditeurs : commentaires / annotations
 */
export enum TabEditorIndex {
  commentaire = 0,
  annotations = 2
}
