export const environment = {
  production: true,
  env: 'prod',
  baseUrl: 'https://api.univ-paris8.fr',
  host: 'api.univ-paris8.fr',
  codEtu: '',
  urlSentry: 'https://1b693becd1ed4eefb8b84991ac4e109b@sentry.univ-paris8.fr/4',
  userLdapTest: '',
  numeroEmploiTest: '',
  monacoConfig:  {
    baseUrl: '/admin/assets', // configure base path for monaco editor
  },
  monacoHelpdeskConfig:  {
    baseUrl: '/helpdesk/assets', // configure base path for monaco editor
  }

};
